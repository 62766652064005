var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blue-cont"},[_c('section',{staticClass:"box"},[(_vm.progressData)?_c('div',{staticClass:"top-title"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(this.$store.state.user.userName)+"，您好")]),_c('p',[_vm._v("当前办理项目："+_vm._s(_vm.progressData.productName))]),_c('p',[_vm._v("签约时间： "+_vm._s(_vm.progressData.signTime))])]):_vm._e(),_c('section',{staticClass:"content"},[_c('p',{staticClass:"tit"},[_vm._v("推荐信/ 赴港计划书")]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"data":_vm.manuscriptData.writingArticlesList,"border":"","resizable":"","header-cell-style":{ backgroundColor: '#F5F7FA', color: '#606266', fontWeight: 'bold' }}},[_c('el-table-column',{attrs:{"prop":"name","label":"文档名称","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.name)+" "),(row.supplementListNameExtraJson && row.supplementListNameExtraJson.organization)?_c('span',[_vm._v("("+_vm._s(row.supplementListNameExtraJson.organization)+")")]):_vm._e(),(row.supplementListNameExtraJson && row.supplementListNameExtraJson.applicant)?_c('span',[_vm._v("【"+_vm._s(row.supplementListNameExtraJson.applicant)+"】")]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态","show-overflow-tooltip":"","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 1)?_c('span',[_vm._v("待提交")]):_vm._e(),(row.status === 2)?_c('span',[_vm._v("待确认")]):_vm._e(),(row.status === 3)?_c('span',[_vm._v("待核对")]):_vm._e(),(row.status === 4)?_c('span',[_vm._v("待审核")]):_vm._e(),(row.status === 5)?_c('span',[_vm._v("驳回")]):_vm._e(),(row.status === 6)?_c('span',[_vm._v("已定稿")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 2)?_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.openDocument(row, 1, 2)}}},[_vm._v("编辑")]),(row.id)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.commitDocument(row)}}},[_vm._v("编辑完成,提交")]):_vm._e()],1):_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.openDocument(row, 0, 1)}}},[_vm._v("查看")])]}}])})],1),_c('p',{staticClass:"tit"},[_vm._v("其他文件")]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"data":_vm.manuscriptData.otherFileList,"border":"","resizable":"","header-cell-style":{ backgroundColor: '#F5F7FA', color: '#606266', fontWeight: 'bold' }}},[_c('el-table-column',{attrs:{"prop":"name","label":"文档名称","show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.name)+" "),(row.supplementListNameExtraJson && row.supplementListNameExtraJson.organization)?_c('span',[_vm._v("("+_vm._s(row.supplementListNameExtraJson.organization)+")")]):_vm._e(),(row.supplementListNameExtraJson && row.supplementListNameExtraJson.applicant)?_c('span',[_vm._v("【"+_vm._s(row.supplementListNameExtraJson.applicant)+"】")]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态","show-overflow-tooltip":"","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 1)?_c('span',[_vm._v("待提交")]):_vm._e(),(row.status === 2)?_c('span',[_vm._v("待确认")]):_vm._e(),(row.status === 3)?_c('span',[_vm._v("待核对")]):_vm._e(),(row.status === 4)?_c('span',[_vm._v("待审核")]):_vm._e(),(row.status === 5)?_c('span',[_vm._v("驳回")]):_vm._e(),(row.status === 6)?_c('span',[_vm._v("已定稿")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 2)?_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.openDocument(row)}}},[_vm._v("编辑")]),(row.id)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.commitDocument(row)}}},[_vm._v("编辑完成,提交")]):_vm._e()],1):_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openDocument(row)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.downloadLoading},on:{"click":function($event){return _vm.downloadDocument(row)}}},[_c('i',{staticClass:"el-icon-download"}),_c('span',[_vm._v("下载文件")])])]}}])})],1)],1)]),_c('Drawio',{attrs:{"draw-href":_vm.drawHref,"show-copy":false,"show-doc":false,"visible":_vm.drawDialogShow,"show-save-btn":_vm.saveBtnShow},on:{"update:visible":function($event){_vm.drawDialogShow=$event},"update:showSaveBtn":function($event){_vm.saveBtnShow=$event},"update:show-save-btn":function($event){_vm.saveBtnShow=$event},"save":_vm.submitDrawData}}),_c('PlanDialog',{attrs:{"visible":_vm.planDialogVisible,"can-edit":_vm.planCanEdit,"plan-data":_vm.planData},on:{"update:visible":function($event){_vm.planDialogVisible=$event},"update:planData":function($event){_vm.planData=$event},"update:plan-data":function($event){_vm.planData=$event}}}),(_vm.$store.state.type==='优才')?_c('show-code'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }