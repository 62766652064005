<script>
import { postProspectusSave } from '../../../api/manuscript'
import { effectiveLength } from '@/utils'
import _ from 'lodash'
export default {
    name: "PlanDialog",
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        planData: {
            type: Object,
            default: () => {
                return {
                    career_achievement: '',
                    professional_skills: '',
                    academic_achievement: '',
                    plan_detail: '',
                    qualifications_and_awards: '',
                    other: ''
                }
            }
        },
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible
            },
            set(val) {
                this.$emit("update:visible", val)
            }
        },
        dialogData: {
            get() {
                return this.planData;
            },
            set(val) {
                this.$emit("update:planData", val)
            }
        }
    },
    methods: {
        async confirm() {
            await postProspectusSave({ ...this.dialogData })
            this.$message.success('保存成功')
            this.dialogVisible = false
        },
        effectiveLength,
        // 静默保存用户填写的内容
        silentSave: _.debounce(function() {
            postProspectusSave({ ...this.dialogData })
        }, 2000, {
            trailing: true,
        })

    }
}
</script>

<template>
    <!--  赴港计划书弹窗  -->
    <el-dialog title="赴港计划书" center lock-scroll width="60%" top="10vh" custom-class="fg-dialog" :visible.sync="dialogVisible">
        <div class="fg-content">
            <div class="title">应香港入境处最新要求，赴港计划书格式、内容作出调整，共分为六部分，每部分不得超过100字(不含标点)。其中“赴港具体计划”仅代表个人预期规划，无须严格执行。</div>
            <div class="item">
                <div class="item-title">
                    1、【主要事业成就】<br />
                    请以不超过100字简介你的主要事业成就，包括你在主要工作项目上曾担当的角色、你工作期间曾服务的知名机构/单位或你对有关工作项目及机构的重大贡献：
                </div>
                <div class="item-content">
                    <el-input
                        v-model="dialogData.career_achievement"
                        :disabled="!canEdit"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        @input="silentSave"
                    />
                    <span
                        class="word-num"
                        :style="!canEdit ? 'background: #f5f7fa' : 'background: #fff'"
                    >
                        {{ effectiveLength(dialogData.career_achievement) }}
                    </span>
                </div>
            </div>
            <div class="item">
                <div class="item-title">
                    2、【专业技能成就】<br />
                    请以不超过100字简介你的专业技能或人才清单内相关专业的成就，以及有关技能/专业如何对香港经济或社会做出重大贡献：
                </div>
                <div class="item-content">
                    <el-input
                        v-model="dialogData.professional_skills"
                        :disabled="!canEdit"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        @input="silentSave"
                    />
                    <span
                        class="word-num"
                        :style="!canEdit ? 'background: #f5f7fa' : 'background: #fff'"
                    >
                        {{ effectiveLength(dialogData.professional_skills) }}
                    </span>
                </div>
            </div>
            <div class="item">
                <div class="item-title">
                    3、【学业成就】<br />
                    请以不超过100字简介你的学业成就，包括你的学业成绩或曾取得的奖项（如有）：
                </div>
                <div class="item-content">
                    <el-input
                        v-model="dialogData.academic_achievement"
                        :disabled="!canEdit"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        @input="silentSave"
                    />
                    <span
                        class="word-num"
                        :style="!canEdit ? 'background: #f5f7fa' : 'background: #fff'"
                    >
                        {{ effectiveLength(dialogData.academic_achievement) }}
                    </span>
                </div>
            </div>
            <div class="item">
                <div class="item-title">
                    4、【未来发展计划】<br />
                    请以不超过100字简介你未来在香港发展事业的计划，包括你与本地商业或学业机构联系及合作的详情（如有）：
                </div>
                <div class="item-content">
                    <el-input
                        v-model="dialogData.plan_detail"
                        :disabled="!canEdit"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        @input="silentSave"
                    />
                    <span
                        class="word-num"
                        :style="!canEdit ? 'background: #f5f7fa' : 'background: #fff'"
                    >
                        {{ effectiveLength(dialogData.plan_detail) }}
                    </span>
                </div>
            </div>
            <div class="item">
                <div class="item-title">
                    5、【专业资格及奖项】<br />
                    请以不超过100字简介你所属的专业界别内曾获取由国家或国际认可/著名团体颁奖的专业资格，及曾取得的奖项（如有）：
                </div>
                <div class="item-content">
                    <el-input
                        v-model="dialogData.qualifications_and_awards"
                        :disabled="!canEdit"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        @input="silentSave"
                    />
                    <span
                        class="word-num"
                        :style="!canEdit ? 'background: #f5f7fa' : 'background: #fff'"
                    >
                        {{ effectiveLength(dialogData.qualifications_and_awards) }}
                    </span>
                </div>
            </div>
            <div class="item">
                <div class="item-title">
                    6、【其他材料】<br />
                    请以不超过100字简介你认为对评核你的申请非常重要的其他材料：
                </div>
                <div class="item-content">
                    <el-input
                        v-model="dialogData.other"
                        :disabled="!canEdit"
                        type="textarea"
                        :rows="4"
                        placeholder="请输入内容"
                        @input="silentSave"
                    />
                    <span
                        class="word-num"
                        :style="!canEdit ? 'background: #f5f7fa' : 'background: #fff'"
                    >
                        {{ effectiveLength(dialogData.other) }}
                    </span>
                </div>
            </div>
        </div>
        <div v-if="canEdit" slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirm">保 存</el-button>
        </div>
    </el-dialog>
</template>

<style>
.fg-dialog {
    height: 80%;
    border-radius: 8px;
}
.fg-dialog .fg-content {
    height: 100%;
    overflow-y: scroll;
}
.fg-dialog .fg-content::-webkit-scrollbar{
    display:none;
}
.fg-dialog .el-dialog__body {
    height: 85%;
}
.title {
    font-size: 14px;
    font-weight: bold;
}
.item-title {
    margin: 20px 0;
}
.item-content {
    position: relative;
}
.word-num {
    position: absolute;
    right: 10px;
    bottom: 4px;
    line-height: 14px;
    font-size: 12px;
    color: #909399;
}
</style>
