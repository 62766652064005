<!--
 * @Author: zhai
 * @Description: 二维码弹窗
-->
<template>
    <el-dialog
        title=""
        width="550px"
        top="30vh"
        :visible.sync="visible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        class="el-dialog-sc"
        @close="dialogClose"
    >
        <div class="qrc-model">
            <div class="item">
                <div class="tit">微信扫码登录</div>
                <div class="tit">填写上传更便捷</div>
                <img src="~@/assets/img/sun_qr.png" alt="小程序太阳码" />
                <div class="mc">智汇银河小程序</div>
            </div>
            <div v-if="data.gzh_status === 1" class="item">
                <div class="tit">微信扫码关注</div>
                <div class="tit">消息通知更及时</div>
                <img src="~@/assets/img/public_qrcode.png" alt="公众号二维码" />
                <div class="mc">智汇银河Service公众号</div>
            </div>
        </div>
        <span slot="footer">
            <el-button @click="doNotRemind">暂不提醒</el-button>
            <el-button type="primary" @click="dialogClose">关闭弹窗</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { getNotifyStatus, postNotifySubmit } from "@/api/workfow"
export default {
    props: {
        // show: {
        //     type: Boolean,
        //     default: false
        // },
        // data: {
        //     type: Object,
        //     default: () => {}
        // }
    },
    data() {
        return {
            visible: false,
            envs: process.env.VUE_APP_ENV === 'production',
            data: {}
        }
    },
    created() {
        this.getNotifyStatus()
    },
    methods: {
        // 获取数据
        async getNotifyStatus() {
            const { data } = await getNotifyStatus({})
            this.visible = data.status === 1 ? true : false
            this.data = data
            console.log(this.data)
        },
        // 暂不提醒
        async doNotRemind() {
            await postNotifySubmit({
                option: 0
            })
            this.visible = false
        },
        // 关闭弹窗
        dialogClose() {
            this.visible = false
        },
    }
}
</script>

<style lang="less" scoped>
.el-dialog-sc {
    /deep/ .el-dialog__footer {
        border-top: none;
        text-align: center;
        padding-bottom: 26px;
    }
    /deep/ .el-dialog {
        border-radius: 12px;
        background: linear-gradient(175deg, #EFF7FF 11.96%, #FFF 69.97%);
    }
    /deep/ .el-dialog__close {
        font-size: 18px;
    }
    .qrc-model {
        width: 100%;
        display: flex;
        font-style: normal;
        align-items: center;
        justify-content: center;
        // padding: 0 48px;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 50px;
            .tit {
                width: 140px;
                color: #000;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
            }
            img {
                width: 170px;
                height: 170px;
                margin-bottom: 17px;
                margin-top: 24px;
            }
            .mc {
                color: #979797;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

</style>
