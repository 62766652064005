<!--
 * @Author: filename
 * @Description: 文书稿件
-->
<template>
    <div class="blue-cont">
        <section class="box">
            <!-- header -->
            <div v-if="progressData" class="top-title">
                <p class="name">{{ this.$store.state.user.userName }}，您好</p>
                <p>当前办理项目：{{ progressData.productName }}</p>
                <p>签约时间： {{ progressData.signTime }}</p>
            </div>
            <!-- list -->
            <section class="content">
                <p class="tit">推荐信/ 赴港计划书</p>
                <el-table
                    v-loading="isLoading"
                    :data="manuscriptData.writingArticlesList"
                    border resizable
                    :header-cell-style="{ backgroundColor: '#F5F7FA', color: '#606266', fontWeight: 'bold' }"
                >
                    <el-table-column prop="name" label="文档名称" show-overflow-tooltip align="center">
                        <template slot-scope="{row}">
                            <div>{{ row.name }}
                                <span v-if="row.supplementListNameExtraJson && row.supplementListNameExtraJson.organization">({{ row.supplementListNameExtraJson.organization }})</span>
                                <span v-if="row.supplementListNameExtraJson && row.supplementListNameExtraJson.applicant">【{{ row.supplementListNameExtraJson.applicant }}】</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" show-overflow-tooltip align="center" width="100">
                        <template slot-scope="{row}">
                            <span v-if="row.status === 1">待提交</span>
                            <span v-if="row.status === 2">待确认</span>
                            <span v-if="row.status === 3">待核对</span>
                            <span v-if="row.status === 4">待审核</span>
                            <span v-if="row.status === 5">驳回</span>
                            <span v-if="row.status === 6">已定稿</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="220">
                        <template slot-scope="{row}">
                            <div v-if="row.status === 2">
                                <el-button type="primary" @click="openDocument(row, 1, 2)">编辑</el-button>
                                <el-button v-if="row.id" type="primary" @click="commitDocument(row)">编辑完成,提交</el-button>
                            </div>
                            <!-- <el-button v-else-if="row.status === 6" type="primary" @click="openDocument(row)">查看定稿</el-button> -->
                            <el-button v-else type="primary" @click="openDocument(row, 0, 1)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <p class="tit">其他文件</p>
                <el-table
                    v-loading="isLoading"
                    :data="manuscriptData.otherFileList"
                    border resizable
                    :header-cell-style="{ backgroundColor: '#F5F7FA', color: '#606266', fontWeight: 'bold' }"
                >
                    <el-table-column prop="name" label="文档名称" show-overflow-tooltip align="center">
                        <template slot-scope="{row}">
                            <div>{{ row.name }}
                                <span v-if="row.supplementListNameExtraJson && row.supplementListNameExtraJson.organization">({{ row.supplementListNameExtraJson.organization }})</span>
                                <span v-if="row.supplementListNameExtraJson && row.supplementListNameExtraJson.applicant">【{{ row.supplementListNameExtraJson.applicant }}】</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" show-overflow-tooltip align="center" width="100">
                        <template slot-scope="{row}">
                            <span v-if="row.status === 1">待提交</span>
                            <span v-if="row.status === 2">待确认</span>
                            <span v-if="row.status === 3">待核对</span>
                            <span v-if="row.status === 4">待审核</span>
                            <span v-if="row.status === 5">驳回</span>
                            <span v-if="row.status === 6">已定稿</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="200">
                        <template slot-scope="{row}">
                            <div v-if="row.status === 2">
                                <el-button type="primary" @click="openDocument(row)">编辑</el-button>
                                <el-button v-if="row.id" type="primary" @click="commitDocument(row)">编辑完成,提交</el-button>
                            </div>
                            <el-button v-else type="primary" @click.stop="openDocument(row)">查看</el-button>
                            <el-button type="primary" :loading="downloadLoading" @click="downloadDocument(row)">
                                <i class="el-icon-download" />
                                <span>下载文件</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </section>
        </section>
        <Drawio
            :draw-href="drawHref"
            :show-copy="false"
            :show-doc="false"
            :visible.sync="drawDialogShow"
            :show-save-btn.sync="saveBtnShow"
            @save="submitDrawData"
        />
        <PlanDialog :visible.sync="planDialogVisible" :can-edit="planCanEdit" :plan-data.sync="planData" />
        <show-code v-if="$store.state.type==='优才'" />
    </div>
</template>
<script>
import cryptoAES from '../../utils/cryptoAES'
import Drawio from "./C/Drawio.vue"
import PlanDialog from "./C/PlanDialog.vue"
import { queryWritingDocumentList, commitDocumentChanges, getTopVersionDocumentUrl, getJudgeAndUrl, getReceiveQQdoc,
    getProspectusDetail, postProspectusSubmit, documentBrowseHistory } from '../../api/manuscript'
import { getGangedProgress,saveOrgGraphData } from "@/api/workfow.js"
import { effectiveLength } from '@/utils'
import ShowCode from "@/components/ShowCode/ShowCode.vue";

export default {
    name: "Manuscript",
    components:{
        Drawio,
        PlanDialog,
        ShowCode
    },
    data() {
        return {
            isLoading: false,
            manuscriptData: [],
            isAddMaterial: false,
            progressData: null,
            downloadLoading: false,
            drawDialogShow:false,
            drawHref:'',
            currentId:null,
            saveBtnShow:true,
            planDialogVisible: false,
            planCanEdit: false,
            planData: {},
        }
    },

    created() {
        this.$store.commit("user")
        this.$store.commit("setType")
        this.getDocumentList()
        this.progressList()
    },

    mounted() {
        // 不同浏览器 hidden 名称
        const hiddenProperty = 'hidden' in document ? 'hidden' :
            'webkitHidden' in document ? 'webkitHidden' :
            'mozHidden' in document ? 'mozHidden' :
            null;
        // 不同浏览器的事件名
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
        const onVisibilityChange = () => {
            if (!document[hiddenProperty]) {
                this.getDocumentList()
            }
        }
        document.addEventListener(visibilityChangeEvent, onVisibilityChange);
    },

    methods: {
        async progressList() {
            const data = await getGangedProgress({
                orderId: this.$store.state.user.orderId,
                productType: this.$store.state.type === '优才' ? 1
                            : this.$store.state.type === '专才' ? 2
                            : 3
            })
            this.progressData = data.data
        },

        // 列表
        async getDocumentList() {
            try {
                this.isLoading = true
                const { data } = await queryWritingDocumentList({
                    orderId:this.$store.state.user.orderId
                })
                this.manuscriptData = data
            } finally {
                this.isLoading = false
            }
        },

        // 编辑完成提交
        commitDocument(row) {
            this.$confirm('确定要提交，并确保编辑文档页面已经关闭?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async() => {
                this.isLoading = true
                try {
                    const { orderId, templateId, supplementId, documentType } = row
                    // 赴港计划书并且文档类型不是onlyoffice
                    if (templateId === 195 && documentType !== 1) {
                        const { data } = await getProspectusDetail({ order_id: row.orderId })
                        const arrData = ['career_achievement','professional_skills','academic_achievement',
                        'plan_detail','qualifications_and_awards','other']
                        let validate = true
                        // arrData.forEach(item => {
                        //     if (effectiveLength(data[item]) > 100) {
                        //         validate = false
                        //     }
                        // })
                        // if (!validate) {
                        //     this.$message.error('每段信息不可超过100字，请修改后再试。')
                        //     this.isLoading = false
                        //     return
                        // }
                        const { code } = await postProspectusSubmit({ order_id: orderId, ...data, supplement_id: supplementId })
                        if (code === 200) {
                            this.$message({ type: 'success', message: '提交成功!' })
                            setTimeout(()=> {
                                this.getDocumentList()
                            }, 500)
                        }
                    } else {
                        const { code } = await commitDocumentChanges({
                            id: row.id
                        })
                        // 接收腾讯文档 文案在 CRM 点击接收文档或者批量接收文档时发起调用
                        await getReceiveQQdoc({ supplementIdList: [row.id] })
                        if (code === 200) {
                            this.$message({ type: 'success', message: '提交成功!' })
                            setTimeout(()=> {
                                this.getDocumentList()
                            }, 500)
                        }
                    }
                    this.isLoading = false
                } catch(err) {
                    this.isLoading = false
                }
            })
        },

        // 打开文档或者工具
        async openDocument(row, type = 0, authority) {
            if ([1, 2].includes(authority)) {
                documentBrowseHistory({ // 记录操作
                    supplement_id: row.supplementId,
                    authority: authority,
                    operation_port: 3,
                })
            }
            this.saveBtnShow = true
            const { id,chartData,name,chartPushType,templateId,documentType } = row;
            const u = navigator.userAgent;
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            // 为组织架构图类型且推送的是工具时，这里打开绘图工具
            if([74,178,372].includes(templateId) && chartPushType === 2){
                this.currentId = id;
                this.drawDialogShow = true;
                let filename = '';
                if(chartData && chartData.filename){
                    filename = chartData.filename.split('_')[0] + '_'+id;
                } else {
                    filename = `${name}_${id}`
                }
                const data = chartData ? `${chartData.data}?v=${Date.now()}` : ''
                if(row.status!==2){
                    this.saveBtnShow = false
                }
                this.drawHref = process.env.VUE_APP_DRAW_URL + encodeURI(`?dev=1&embed=1&msg=1&filename=${filename}&url=${data}`);
            } else if (templateId === 195 && documentType !== 1) {
                const { data } = await getProspectusDetail({ order_id: row.orderId })
                this.planData = data
                this.planData.supplement_id = row.supplementId
                this.planDialogVisible = true
                if (type) {
                    this.planCanEdit = true
                } else {
                    this.planCanEdit = false
                }
            } else {
                if (row.documentKey) {
                    const { data } = await getJudgeAndUrl({ supplement_id: row.supplementId })
                    if (data.is_tencent) {
                        if (isiOS) {
                            window.location.href = data.url
                        }else{
                            window.open(data.url)
                        }
                        this.getDocumentList()
                        return
                    }
                    const scope = row
                    const query = {
                        documentKey: scope.documentKey,
                        uid: '2-' + row.customerId,
                        compareFileUrl: this.$utils.toOss(scope.compareFileUrl),
                        token: localStorage.getItem('token'),
                        ...{ account: this.$store.state.user.userName }
                    }
                    const documentInfo = encodeURIComponent(cryptoAES.encrypt(encodeURIComponent(JSON.stringify(query))))
                    if (isiOS) {
                        window.location.href = process.env.VUE_APP_DOC_URL + '/Writing?documentInfo='+ documentInfo
                    }else{
                        window.open(process.env.VUE_APP_DOC_URL + '/Writing?documentInfo='+ documentInfo)
                    }
                } else {
                    if (isiOS) {
                        window.location.href = this.$utils.toOss(JSON.parse(row.documentUrl)[0].url)
                    }else{
                        window.open(this.$utils.toOss(JSON.parse(row.documentUrl)[0].url))
                    }
                }
            }
        },

        // 下载文件
        async downloadDocument(row) {
            const u = navigator.userAgent;
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            try {
                this.downloadLoading = true
                let res = {};
                if (row.documentKey) {
                    res = await getTopVersionDocumentUrl({documentKey: row.documentKey})
                    if (res.code === 200) {
                        if (isiOS) {
                            window.location.href = res.data
                        }else{
                            window.open(res.data)
                        }
                    } else {
                        this.$message({ type: 'error', message: res.msg })
                    }
                } else {
                    // window.open(JSON.parse(row.documentUrl)[0].url)
                    this.$utils.downloadRes(JSON.parse(row.documentUrl)[0].url, JSON.parse(row.documentUrl)[0].name);
                }
            } finally {
                this.downloadLoading = false
            }
        },

        // 提交绘图数据
        submitDrawData(params,isCopy,done){
            const orderId = this.$store.state.user.orderId;
            const { chart_filename,chart_data } = params;
            const blob = this.dataToBlob(chart_data);
            const filename = `${chart_filename}`;
            const file = this.blobToFile(blob,`${chart_filename}.txt`,'text/plain');
            const ossPath = `crm/${process.env.VUE_APP_ENV}/files/org/${ orderId }/${filename}.txt`
            this.$utils.multipartUpload(file,true).then((url)=>{
                //const url = `${this.$CONFIG.OSS_BASE_URL}${res.name}`
                saveOrgGraphData({
                    writingDocumentId:this.currentId,
                    chartData:{
                        data:url,
                        filename:chart_filename,
                    }
                }).then((res)=>{
                    if(res.code === 200){
                        if(!isCopy){
                            this.$message.success("保存成功");
                        }
                        //this.drawDialogShow = false;
                        //this.currentId = null;
                        this.getDocumentList();
                    }
                    done()
                })
            }).catch(()=>{
                this.$message.error('上传失败')
            })
        },

        dataToBlob(data){
            return new Blob([data],{type: 'text/plain'})
        },

        blobToFile(blob,filename,fileType){
            return new File([blob],filename,{type:fileType})
        },
    }
}
</script>

<style lang="less" scoped>
.blue-cont {
    width: 100%;
    height: 130px;
    background: linear-gradient(313deg, #74cdff 0%, #409eff 100%);
}
.box {
    width: 960px;
    margin: 0 auto;
}
.top-title {
    padding-top: 17px;
    height: 130px;
    p {
        color: #fff;
        padding-top: 10px;
    }
    p.name {
        font-size: 20px;
    }
}
.content {
    padding: 10px 0 20px;
}
.tit {
    line-height: 40px;
    font-size: 15px;
    color: #333;
    position: relative;
    padding-left: 15px;
    margin: 5px 0;
    &::before {
        display: block;
        content: "";
        background: #409eff;
        height: 22px;
        width: 5px;
        position: absolute;
        top: 8px;
        left: 0;
    }
}
</style>
<style>
.draw-dialog .el-dialog__body{
    padding:0 20px;
}
</style>
