<template>
    <el-dialog 
        title="在线绘图"
        :visible.sync="drawioVisible"
        width="1800px"
        top="2vh"
        custom-class="draw-dialog"
        :before-close="handleOrgBeforeClose" 
        append-to-body
        :close-on-click-modal="false"
    >
        <div slot="title" style="height:32px">
            <img src="~@/assets/img/login/logo.png" style="margin-right:8px;height:100%"/>
            <label style="font-size:16px">银河集团在线绘图工具</label>
        </div>
        <div>
            <iframe
                ref="drawIframe" 
                allow="clipboard-write" 
                :src="drawHref"
                width="100%" 
                :height="iframeHeight+'px'" 
                style="border-top-width:1px;border-left-width:1px;border-right-width:1px"
            />
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button v-if="showSaveBtn && showCopy" type="warning" @click="copyImage">生成图片并复制</el-button>
            <el-button v-if="showDoc" type="success" @click="openDocument">打开文档</el-button>
            <el-button v-if="showSaveBtn" type="primary" :loading="saveLoading" @click="saveDraw('save')">保存</el-button>
        </span>
        <el-dialog
            width="400px"
            title="保存模板"
            :visible.sync="innerVisible"
            append-to-body
        >
            <el-form label-width="90px">
                <el-form-item label="模板名称：">
                    <el-input/>
                </el-form-item>
                <el-form-item label="模板状态：">
                    <el-switch/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button>取消</el-button>
                <el-button type="primary">确定</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>
<script>
export default {
    props:{
        showSaveBtn:{
            type:Boolean,
            default:true
        },
        visible:{
            type:Boolean,
            default:false 
        },
        drawHref:{
            type:String,
            default:''
        },
        showCopy:{
            type:Boolean,
            default:true
        },
        showDoc:{
            type:Boolean,
            default:true
        }
    },

    data(){
        return {
            iframeHeight:700,
            innerVisible:false,
            isCopy:false,
            saveLoading:false
        }
    },

    computed:{
        drawioVisible:{
            get(){
                return this.visible;
            },
            set(val){
                this.$emit("update:visible",val); 
            }
        }
    },

    beforeDestroy(){
        window.removeEventListener("message",this.handleDrawOperation);
        window.removeEventListener("resize",this.calcIframeHeight);
    },

    mounted(){
        this.calcIframeHeight();
        window.addEventListener("message",this.handleDrawOperation);
        window.addEventListener("resize",this.calcIframeHeight);
        window.onbeforeunload = ()=>{
            if(this.drawioVisible){
                return '';
            }
        };
    },

    methods:{
        // 动态计算iframe 高度
        calcIframeHeight(){
            const clientHeight = document.documentElement.clientHeight;
            this.iframeHeight = clientHeight - 2*clientHeight*0.02 - 62 - 62
        },

        // 处理绘图操作
        handleDrawOperation(e){
            try{
                const data = JSON.parse(e.data)
                if(data && typeof data === 'object'){
                    if(data.type === 'copyImage'){
                        this.generateImage(data.data);
                    }
                    if(data.type === 'getFileData'){
                        this.$emit("save",data.data,this.isCopy,()=>{
                            this.saveLoading = false;
                            this.isCopy = false
                        })
                    }
                }
            }catch(e){
                console.log(e)
            }
        },

        // 复制图片
        copyImage(){
            let el = this.$refs.drawIframe;
            const iframe = el.contentWindow;
            iframe.postMessage("copyImage","*");
        },

        // 生成复制的图片
        generateImage(imgSrc){
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = imgSrc;
            img.onload = ()=>{
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
                // 将canvas转为blob
                canvas.toBlob(async (blob) => {
                    const data = [
                        // eslint-disable-next-line no-undef
                        new ClipboardItem({
                            [blob.type]: blob,
                        }),
                    ];
                    await navigator.clipboard.write(data).then(
                        () => {
                            this.$message.success("图片复制成功")
                            this.isCopy = true;
                            if(this.showSaveBtn){
                                this.saveDraw()
                            }
                        },
                        (e) => {
                            console.error(e);
                        }
                    );
                },'image/png');
            }
        },

        // 保存绘图
        saveDraw(flag){
            if(flag){
                this.saveLoading = true;
            }
            let el = this.$refs.drawIframe;
            el.contentWindow.postMessage("getFileData","*")
        },

        // 保存文档
        openDocument(){
            this.$emit("open-document");
        },

        // 关闭确认
        handleOrgBeforeClose(done){
            if(this.showSaveBtn === false){
                return done()
            }
            this.isCopy = true;
            this.saveDraw();
            done();
        }
    }
}
</script>
<style>
.draw-dialog .el-dialog__body{
    padding:0 20px !important;
}
</style>