/**
 * 输入字数统计规则
 * 非中文、英文、数字的字符不计算长度
 * 连接的英文和数字统计为1个长度
 * @param text 输入总字符串
 */
export function effectiveLength(text) {
    let length = 0
    if (!text)
        return length
    const strArr = text.split('')
    const chineseRegex = /[\u4E00-\u9FA5]/ // 中文字符
    const alphanumericRegex = /[a-zA-Z0-9]/ // 英文和数字
    let lastStr = ''
    strArr.forEach((i) => {
        if (chineseRegex.test(i)) // 如果是中文直接统计上长度
            length += 1
        if (alphanumericRegex.test(i) && !alphanumericRegex.test(lastStr)) // 如果当前字符是英文数字，并且与之相连的上一个字符不为英文数字时，统计上长度
            length += 1
        lastStr = i
    })
    return length
}
